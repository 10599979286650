
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { IDataset, IDatasetCreate } from "@/interfaces";
import { readDatasets, readDataset } from "@/store/dataset/getters";
import {
  dispatchGetDatasets,
  dispatchDeleteDataset,
} from "@/store/dataset/actions";
import { readToken } from "@/store/main/getters";
import { readModel, readFirstLabelContainer, readLabels, readAllLabelsByWorkspaceId } from "@/store/model/getters";
import { api } from "@/api";
import { debounce } from 'lodash'; 

import {
  IColumnInformation,
  IModelCreate,
  IModelFilterLabel,
  IModelFilterString,
  IModelFilterTimestamp,
} from "@/interfaces";

import ConversationBrowser from "@/components/ConversationBrowser.vue";
import TextEditor from "@/components/TextEditor.vue";
import MarkdownIt from 'markdown-it';
import FileDrop from '@/components/FileDrop.vue';
import Fuse from "fuse.js";


@Component({
  components: {
    ConversationBrowser,
    TextEditor,
    FileDrop
  },
})
export default class Tags extends Vue {
  private tags: any[] = [
    // {
    //   name: "Sample Tag",
    //   description: "This is a sample tag description",
    //   date_added: "2024-03-20",
    //   id: 1,
    //   file: "sample.txt",
    //   added_by: "John Doe",
    //   num_conversations: 5
    // },
    // {
    //   name: "New ustomer Tag",
    //   description: "This is a sample tag description",
    //   date_added: "2024-03-20",
    //   id: 2,
    //   file: "sample.txt",
    //   added_by: "John Doe",
    //   num_conversations: 5
    // },
    // {
    //   name: "Tag for Phones",
    //   description: "This is a sample tag description",
    //   date_added: "2024-03-20",
    //   id: 3,
    //   file: "sample.txt",
    //   added_by: "John Doe",
    //   num_conversations: 5
    // },
    // {
    //   name: "Tag Promotion Iphone",
    //   description: "This is a sample tag description",
    //   date_added: "2024-03-20",
    //   id: 4,
    //   file: "sample.txt",
    //   added_by: "John Doe",
    //   num_conversations: 5
    // }
  ];

  private nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string) => (v && v.length >= 5) || 'Name must be at least 5 characters'
  ];

  private descriptionRules = [
    (v: string) => !!v || 'Description is required',
    (v: string) => (v && v.length >= 5) || 'Description must be at least 5 characters'
  ];

  private showForm: boolean = false;
  private isFormValid: boolean = false;
  private hasFile: boolean = false;

  private newTag = {
    metadataColumn: '',
    name: '',
    description: '',
    file: null as File | null
  };

  private isLoading = false;
  private deleteDialog = false;
  private isDeleting = false;
  private tagToDelete: any = null;
  private metaDataColumns: any[] = [];

  private searchQuery: string = "";
  private displayedTags: any[] = [];
  private fuse: any;
  private fuseOptions = {
    keys: ["name"],
    includeScore: true,
    threshold: 0.4,
  };

  private sortOrder: string = 'newest';
  private sortOptions = [
    { text: 'Newest First', value: 'newest' },
    { text: 'Oldest First', value: 'oldest' }
  ];

  private currentPage: number = 1;
  private itemsPerPage: number = 9;

  public createDatasetColumns() {
    
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {

        this.metaDataColumns = [];
        Object.keys(this.dataset!.meta_data.columns).forEach((columnKey) => {
          const column = this.dataset!.meta_data.columns[columnKey];
          // Skip contact_id column
          if (column.name === 'contact_id' || column.mapped_name === 'contact_id') {
            return;
          }
          this.metaDataColumns.push({
            key: columnKey,
            final_name: column.mapped_name || column.name,
            ...column
          });
        });
    }
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  private handleFileSelect(files: FileList) {
    this.newTag.file = files[0];
    this.hasFile = true;
    this.validateForm();
  }

  private validateForm() {
    const nameValid = Boolean(this.newTag.name && this.newTag.name.length >= 5);
    const descValid = Boolean(this.newTag.description && this.newTag.description.length >= 5);
    const columnValid = Boolean(this.newTag.metadataColumn);
    this.isFormValid = nameValid && descValid && columnValid && this.hasFile;
  }

  @Watch('newTag.metadataColumn')
  @Watch('newTag.name')
  @Watch('newTag.description')
  onInputChange() {
    this.validateForm();
  }

  private cancelForm() {
    this.showForm = false;
    this.newTag = {
      metadataColumn: '',
      name: '',
      description: '',
      file: null
    };
    this.isFormValid = false;
    this.hasFile = false;
    if (this.$refs.form) {
      (this.$refs.form as any).reset();
    }
  }

  @Watch('showForm')
  onShowFormChange(newVal: boolean) {
    if (!newVal) {
      this.cancelForm();
    }
  }

  private async submitTag() {
    this.validateForm();
    if (!this.isFormValid) {
        return;
    }

    this.isLoading = true;
    try {
        const response = await api.createDatasetTag(
            readToken(this.$store),
            parseInt(this.$router.currentRoute.params.workspaceid, 10),
            parseInt(this.$router.currentRoute.params.id, 10),
            this.newTag.metadataColumn,
            this.newTag.name,
            this.newTag.description,
            this.newTag.file
        );

        await this.fetchTags();
        this.cancelForm();

        this.$store.commit('addNotification', {
            content: 'Tag created successfully',
            color: 'success',
        });

    } catch (error: any) {
        console.error('Error creating tag:', error);
        this.$store.commit('addNotification', {
            content: `${error.response?.data?.detail || 'An unexpected error occurred'}`,
            color: 'error',
        });
    } finally {
        this.isLoading = false;
    }
  }

  async mounted() {
    await this.fetchTags();
    this.createDatasetColumns();
  }

  private async fetchTags() {
    try {
      const response = await api.getDatasetTags(
        readToken(this.$store),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      );
      this.tags = response.data;
      this.displayedTags = [...this.tags];
      this.sortTags();
      this.initializeFuse();
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }

  private confirmDelete(tag: any) {
    this.tagToDelete = tag;
    this.deleteDialog = true;
  }

  private async deleteTag() {
    if (!this.tagToDelete) return;

    this.isDeleting = true;
    try {
        await api.deleteDatasetTag(
            readToken(this.$store),
            parseInt(this.$router.currentRoute.params.workspaceid, 10),
            parseInt(this.$router.currentRoute.params.id, 10),
            this.tagToDelete.id
        );

        await this.fetchTags();

        this.$store.commit('addNotification', {
            content: 'Tag deleted successfully',
            color: 'success',
        });
    } catch (error: any) {
        console.error('Error deleting tag:', error);
        this.$store.commit('addNotification', {
            content: `${error.response?.data?.detail || 'An unexpected error occurred'}`,
            color: 'error',
        });
    } finally {
        this.isDeleting = false;
        this.deleteDialog = false;
        this.tagToDelete = null;
    }
  }

  private initializeFuse() {
    this.fuse = new Fuse(this.tags, this.fuseOptions);
  }

  private filterTags() {
    if (!this.searchQuery || this.searchQuery.trim() === "") {
      this.displayedTags = [...this.tags];
    } else {
      const results = this.fuse.search(this.searchQuery);
      this.displayedTags = results.map(result => result.item);
    }
    this.sortTags();
  }

  private sortTags() {
    this.displayedTags = [...this.displayedTags].sort((a, b) => {
      return this.sortOrder === 'newest' ? b.id - a.id : a.id - b.id;
    });
  }

  get filteredTags() {
    return this.displayedTags;
  }

  // Add computed properties for pagination
  get paginatedTags() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredTags.slice(start, end);
  }

  get totalPages() {
    return Math.ceil(this.filteredTags.length / this.itemsPerPage);
  }

  @Watch('searchQuery')
  @Watch('sortOrder')
  onFilterChange() {
    // Reset to first page when filters change
    this.currentPage = 1;
  }
}
