
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { IDataset, IDatasetCreate } from "@/interfaces";
import { readDatasets, readDataset } from "@/store/dataset/getters";
import {
  dispatchGetDatasets,
  dispatchDeleteDataset,
} from "@/store/dataset/actions";
import { readToken } from "@/store/main/getters";
import { readModel, readFirstLabelContainer, readLabels, readAllLabelsByWorkspaceId } from "@/store/model/getters";
import { api } from "@/api";
import { debounce } from 'lodash'; 

import {
  IColumnInformation,
  IModelCreate,
  IModelFilterLabel,
  IModelFilterString,
  IModelFilterTimestamp,
  IModelFilterTag,
} from "@/interfaces";

import ConversationBrowser from "@/components/ConversationBrowser.vue";
import TextEditor from "@/components/TextEditor.vue";
import MarkdownIt from 'markdown-it';

@Component({
  components: {
    ConversationBrowser,
    TextEditor,
  },
})
export default class UserProfile extends Vue {
  public searchError: any = null;
  public searchQuery: string = "";
  public tags: any[] = [];
  public queryResponse: any = [];
  public selectedMetadata: any[] = [];
  public metadataOptions: any[] = [];
  public loadingButton: boolean = false;
  public page: number = 1;
  public systemColumns: any = {}
  public cardinality: number = -1;
  public searchMode: string = "hybrid";

  public selectedFilterColumn: string = "";
  public columnValues: any = {};
  public pickedColumnValues: any[] = [];
  public filterTypeDataset: string = "must";
  public datasetFilters: any = [];
  public columns: any[] = [];
  public rows: any[] = [];
  public rowsToShow: any[] = [];
  public expandedRowDatasetFilter: number | null = null;
  public menuEnd: boolean = false;
  public menuStart: boolean = false;
  public startDate: string = "";
  public endDate: string = "";
  public similarity: number = 70;
  public speakerColumnValues: any = {};
  public speedSpeakerToggle: any = null;
  public componentKey: number = 0;
  public searchInput: string = "";
  public showLabels: boolean = false;

  public connectedModels: any[] = [];
  public modelFilters: any[] = [];
  public pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
  public expandedRowModelFilter: number | null = null;

  public newSearchSettingName: string = "";
  public searchSettings: any = [];
  public loadingSearchSettings: boolean = false;
  private debouncedOnSearchInput = debounce(this.performSearch, 300);
  public loadingColumnValues: boolean = false;
  public md = new MarkdownIt();

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public headersModelFilter = [
    { text: "Model", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public headersSettings = [
    {
      text: "id",
      align: "left",
      value: "id",
    },
    {
      text: 'Name',
      align: 'left',
      value: 'name'
    },
    // { text: 'Settings', value: 'settings', align: 'left'},
    { text: 'Default', value: 'default', align: 'left'},
    { text: '', value: '', align: 'left', sortable: false},
    { text: '', value: '', align: 'left', sortable: false},
  ];

  public headersTagFilter = [
    { text: "Tag", value: "tag_name" },
    { text: "Filter type", value: "filter_type" },
    { text: "", value: "actions", sortable: false },
  ];

  public tagFilters: IModelFilterTag[] = [];
  public pickedTag: any = null;

  public keywordQuery: string = "";
  public aiQuery: string = "";

  public selectedFilterModel: any = null;
  public modelSearchInput: string = "";
  public labelSearchInput: string = "";

  public sortOrder: string = 'asc';
  public modelSortOrder: string = 'asc';

  public tagSearchInput: string = "";

  public tagSortOrder: string = 'asc';

  get sortedModels() {
    return [...this.connectedModels]
      .filter(model => model.status === 'deployed') // Filter based on model.status === 'deployed'
      .sort((a, b) => {
        return this.modelSortOrder === 'asc' ? a.id - b.id : b.id - a.id;
      });
  }

  public getMarked(text: string) {
    try {
      return this.md.render(text);
    } catch (e) {
      return text;
    }
  }

  public getLabel(labelId: number) {
    return this.labels.find((label) => label.id === labelId);
  }

  @Watch('searchInput')
  public onSearchInput(searchQuery: string) {
    // Use the debounced version of performSearch when search input changes
    this.debouncedOnSearchInput(searchQuery);
  }

  public async performSearch(searchQuery: string) {
    this.loadingColumnValues = true;
    if ( this.columnValues.column_id !== undefined) {
      const allFilters = [...this.datasetFilters, ...this.modelFilters, ...this.tagFilters];
    await api.filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.columnValues.column_id, // should always exist
        allFilters, // should have filters here
        searchQuery
      )
      .then((r) => {
        this.columnValues = r.data;
        console.log("look at array", this.columnValues.column_values);
        this.loadingColumnValues = false;
      })
      .catch((error) => {
        this.columnValues = {};
        this.loadingColumnValues = false;
        console.log("filterError", error.response);
      });
    }
  }

  get searchSettingsNameValidator() {
    if (this.newSearchSettingName.trim().length > 0) {
      let wasFound: boolean = false
      this.searchSettings.forEach((setting) => {
        if (setting.name === this.newSearchSettingName.trim()) {
          wasFound = true;
        }
      });
      return wasFound;
    } else {
      return true;
    }
  }
  public unpackFilters() {
    this.searchSettings.forEach((setting) => {
      if (setting.default && setting.id !== -1) {
        console.log("unpacking filters:", setting);
        this.datasetFilters = [...setting.settings.dataset_filters];
        this.modelFilters = [...setting.settings.model_filters];
        this.tagFilters = [...(setting.settings.tag_filters || [])];
        this.selectedMetadata = [...setting.settings.selected_columns];
        this.datasetFilters.forEach((filter) => {
          if (filter.store_column_name === "speaker" && filter.filter_type === "must" && filter.filter_values.length === 1) {
            this.speedSpeakerToggle = filter.filter_values[0];
          }
        });
      } else if (setting.default && setting.id === -1) {
        console.log("unpacking filters:", setting);
        this.datasetFilters = [];
        this.modelFilters = [];
        this.tagFilters = [];
        this.speedSpeakerToggle = null;
      }
    });
  }

  public specialColumnMapper = {
    speaker: "speaker",
    filename: "filename",
  };


  public async prepareAndSaveSearchSettings() {
    const searchSettings: any = {
      dataset_filters: {},
      model_filters: {},
      tag_filters: {},
      selected_columns: [],
      miscellaneous: {}
    };
    searchSettings.dataset_filters = this.datasetFilters;
    searchSettings.model_filters = this.modelFilters;
    searchSettings.tag_filters = this.tagFilters;
    searchSettings.selected_columns = this.selectedMetadata;
    searchSettings.miscellaneous = {};
    
    this.saveSearchSettings(searchSettings);
  }

  public async setDefault(id: number) {
    console.log("setting default with id", id)
    this.loadingSearchSettings = true;
    await api
      .changeSearchSettings(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        id
      )
      .then((r) => {
        
      })
      .catch((error) => {
        console.log("error when setting default search settings", error);
      });
      await this.getSearchSettings();
      await this.unpackFilters();
      this.componentKey += 1;
      this.search(true);
      this.loadingSearchSettings = false;
  }

  public async deleteSearchSettings(id: number) {
    console.log("id", id)
    this.loadingSearchSettings = true;
    await api
      .removeSearchSettings(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        id
      )
      .then((r) => {
        this.searchSettings = this.searchSettings.filter((setting) => setting.id !== id);
      })
      .catch((error) => {
        console.log("error when deleting search settings", error);
      });
      await this.getSearchSettings();
      this.unpackFilters();
      this.search(true);
      this.loadingSearchSettings = false;
  }

  public async saveSearchSettings(searchSettings: any) {
    
    await api
      .saveSearchSettings(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        searchSettings,
        this.newSearchSettingName,
      )
      .then((r) => {
        this.searchSettings.push(r.data);
        this.newSearchSettingName = "";
      })
      .catch((error) => {
        console.log("error when getting search settings", error);
      });
  }


  public async getSearchSettings() {
    this.searchSettings = [{ name: "Default settings", settings: {}, default: false, id: -1}];

    await api
      .getSearchSettings(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.searchSettings.push(...r.data);
        console.log("search settings", this.searchSettings);

      // Check if there is an object with default = true
      const hasDefault = this.searchSettings.some(setting => setting.default);

      // If not, make the object with id = -1 default
      if (!hasDefault) {
        const defaultSetting = this.searchSettings.find(setting => setting.id === -1);
        if (defaultSetting) {
          defaultSetting.default = true;
        }
      }
      })
      .catch((error) => {
        console.log("error when getting search settings", error);
      });

  }

  get flexBasis() {
    if (this.queryResponse.hits.length === 0) {
      return 12;
    }
    const itemCount = this.filteredMetadata(this.queryResponse.hits[0]._source).length;
    if (itemCount === 1) {
      return 12; // Take up the whole row
    } else if (itemCount === 2) {
      return 6; // Take up half the row
    } else {
      return 4; // Three items per row
    }
  }
 
  public speedFilter(columnName: string, columnValue: any, hasFilter: boolean) {
    const column = this.metadataOptions.find((column) => column.mapped_name === columnName) || this.metadataOptions.find((column) => column.name === columnName);

    const mappedName = column.mapped_name === null ? column.name : column.mapped_name;

    this.datasetFilters.forEach((filter) =>{
      if (filter.store_column_name === mappedName) {
        this.datasetFilters.splice(this.datasetFilters.indexOf(filter), 1);
      }
    });

    const filter: IModelFilterString = {
          column_id: column.key,
          store_column_name: mappedName,
          filter_values: [columnValue],
          dtype: 'keyword',
          filter_type: 'must',
        };
  
        this.datasetFilters.push(filter);
        console.log(filter);
        this.search(true);
  }

  public speedFilterOff(columnName: string) {
    const column = this.metadataOptions.find((column) => column.mapped_name === columnName) || this.metadataOptions.find((column) => column.name === columnName);

    const mappedName = column.mapped_name === null ? column.name : column.mapped_name;

    this.datasetFilters.forEach((filter) =>{
      if (filter.store_column_name === mappedName) {
        this.datasetFilters.splice(this.datasetFilters.indexOf(filter), 1);
      }
    });
    this.search(true);
  }

  public filteredMetadata(resp: any) {
    const alwaysExcludedKeys = ['_id', 'plain_text', 'user_labels_and_predictions'];
    const conditionallyExcludedKeys = ['start', 'end', this.conversationColumnName];

    const cols = Object.entries(resp)
      .filter(([key, _]) => {
        // Exclude keys that are always excluded
        if (alwaysExcludedKeys.includes(key)) {
          return false;
        }
        // Exclude keys that are conditionally excluded, unless they are selected
        if (conditionallyExcludedKeys.includes(key)) {
          return this.isSelectedDatasetColumns({ name: key });
        }
        // Include all other keys
        return true;
      })
      .map(([key, value]) => {
        // Find the corresponding metadataOption entry
        const option = this.metadataOptions.find(o => o.name === key || (o.mapped_name !== null && o.mapped_name === key));
        
        // Determine if the current column has a filter
        const hasFilter = this.datasetFilters.length > 0 && option && this.datasetFilters.some(filter => {
          // Use mapped_name if it's not null, otherwise use name
          const filterKey = option.mapped_name !== null ? option.mapped_name : option.name;
          return filter.store_column_name === filterKey;
        });

        // Get the dtype from the found metadataOption entry
        const dtype = option ? option.dtype : undefined;

        // Return the column object with the hasFilter boolean and dtype
        return { columnName: key, value: value, hasFilter: hasFilter, dtype: dtype };
      });
    // Return the array of column objects
    return cols;
  }

  get labels() {
    return readAllLabelsByWorkspaceId(this.$store)(+this.$router.currentRoute.params.workspaceid);
  }

  public openPage(id: any, start: any) {
    window.open(`/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/browse?conversation_id=${id}&start=${start}`, '_blank');
  }

  public speakerToggle(speaker: string) {
    // get the column with default values
    const column = this.metadataOptions.filter((column) => column.name === "speaker");

    // we need to check if the column is selected as filter already. If thats the case, remove it.
    this.datasetFilters.forEach((filter) =>{
      if (filter.store_column_name === 'speaker') {
        this.datasetFilters.splice(this.datasetFilters.indexOf(filter), 1);
      }
    });
    if (speaker !== 'all') {
      const filter: IModelFilterString = {
          column_id: column[0].key,
          store_column_name: column[0].name,
          filter_values: [speaker],
          dtype: 'keyword',
          filter_type: 'must',
        };
  
        this.datasetFilters.push(filter);
    }
    this.search(true);
  }

  get hasSpeakerProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const speakerColumn = columnsArray.find(column => column.name === "speaker");

      if (speakerColumn) {
        return speakerColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(column => column.special_column === "conversation");

      if (conversationColumn) {
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get conversationColumnName() {
    if (this.hasConversationProperty !== false) {
      if (this.hasConversationProperty.mapped_name) {
        return this.hasConversationProperty.mapped_name;
      }
      else {
        return this.hasConversationProperty.name;
      }
    } else {
      return "";
    }
  }

  // Dataset columns
  public async getSystemColumns() {
    await api
      .getSystemColumns(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      )
      .then((r) => {
        this.systemColumns = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public toggleSelectionDatasetColumns(item: any) {
    console.log("item", item);
    const index = this.selectedMetadata.findIndex((selectedItem) => selectedItem.name === item.name);
    if (index >= 0) {
      // Item is currently selected, remove it if it's not the last one
      this.selectedMetadata.splice(index, 1);
    } else {
      // Item is not selected, add it to the selection
      this.selectedMetadata.push(item);
    }
    this.search(true);
  }

  public checkMinimumSelectionDatasetColumns() {
    if (this.selectedMetadata.length === 0 && this.metadataOptions.length > 0) {
      this.selectedMetadata = [this.metadataOptions[0]];
    }
  }

  public isSelectedDatasetColumns(item: any) {
    return this.selectedMetadata.some((selectedItem) => selectedItem.name === item.name);
  }

  public selectDeselectAllDatasetColumns() {
    if (this.AreAllSelectedDatasetColumns) {
      this.selectedMetadata = [];
    } else {
      this.selectedMetadata = [...this.metadataOptions];
    }
    this.search(true);
  }

  get AreAllSelectedDatasetColumns() {
    return this.selectedMetadata.length === this.metadataOptions.length;
  }

  get selectDeselectText() {
    if (this.AreAllSelectedDatasetColumns) {
      return "Deselect All";
    } else {
      return "Select All";
    }
  }

  public createDatasetColumns() {
    
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {

      this.metadataOptions = [];
      Object.keys(this.dataset.meta_data.columns).forEach((columnKey) => {
        this.metadataOptions.push({
          key: columnKey,
          ...this.dataset!.meta_data.columns[columnKey]
        });
      });

      // this.metadataOptions = Object.values(this.dataset.meta_data.columns)
      //   .filter(column => (column.special_column !== 'text' && column.name !== 'UniqueID'));
      // const systemColumns = Object.values(this.systemColumns.columns);
      Object.keys(this.systemColumns.columns).forEach((columnKey) => {
        this.metadataOptions.push({
          key: columnKey,
          ...this.systemColumns.columns[columnKey]
        });
      })

    } else {
      this.metadataOptions = [];
    }
    // this.selectedMetadata = this.metadataOptions.filter((column) => column.name === "sub_cluster");
  }

  // Dataset filter

  public deleteDatasetFilter(index: number) {
    this.datasetFilters.splice(index, 1);
    this.search(true);
  }

  public checkAllowedDateStart(date) {
    if (this.endDate) {
      const endDateObj: Date = new Date(this.endDate);
      const currentDate: Date = new Date(date);
      if (endDateObj >= currentDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  }

  get maxAndMinDate() {
    let minDate: any = "";
    let maxDate: any = "";
    // console.log(this.columnInformation.column_values);
    if (this.columnValues.column_values.length > 0) {
      minDate = new Date(
        Math.min(...this.columnValues.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
      maxDate = new Date(
        Math.max(...this.columnValues.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
    }
    return { minDate, maxDate };
  }

  public checkAllowedDateEnd(date) {

    // if (
    //   this.columnValues.column_values.includes(date) &&
    //   currentDate >= new Date(this.maxAndMinDate.minDate)
    // ) {
    if (this.startDate) {
      // need at least 2 months. (+1 and > = 2 months)
      const startDateObj: Date = new Date(this.startDate);
      const currentDate: Date = new Date(date);
      if (currentDate >= startDateObj) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  }

  public addDatasetFilter() {
    if (this.filterTypeDataset !== "timestamp") {
      const filter: IModelFilterString = {
        column_id: this.columnValues.column_id,
        store_column_name: this.columnValues.store_column_name,
        filter_values: this.pickedColumnValues,
        dtype: this.columnValues.dtype,
        filter_type: this.filterTypeDataset,
      };

      this.datasetFilters.push(filter);
    } else {
      const filter: IModelFilterTimestamp = {
        column_id: this.columnValues.column_id,
        store_column_name: this.columnValues.store_column_name,
        dtype: this.columnValues.dtype,
        filter_type: this.filterTypeDataset,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      console.log("timestamp filter");

      this.datasetFilters.push(filter);
      this.endDate = '';
      this.startDate = '';
    }

    this.selectedFilterColumn = "";
    this.columnValues = {};
    this.pickedColumnValues = [];
    this.search(true);  
    
  }


  public expandRowDatasetFilter(index) {
    if (this.expandedRowDatasetFilter === index) {
      this.expandedRowDatasetFilter = null;
    } else {
      this.expandedRowDatasetFilter = index;
    }
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else if (filterType === "tag") {
      return "Tag filter";
    } else {
      return "Unknown";
    }
  }

  public async getDatasetPreview() {
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        // this.headDialog = true;
        this.rowsToShow = this.rows;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public getFilterColumnName(id) {
    let mergedObject = { ...this.dataset!.meta_data.columns, ...this.systemColumns.columns };

    try {
      return mergedObject[id].name
    }
    catch (error) {
      return "None";
    }
  }

  public selectAllDatasetFilter() {
    if (this.AreAllSelectedDatasetFilter) {
      this.pickedColumnValues = [];
    } else {
      this.pickedColumnValues = [];
      this.columnValues.column_values.forEach(element => {
        this.pickedColumnValues.push(element.value);
      });
    }
  }

  get AreAllSelectedDatasetFilter() {
    if (this.columnValues.column_values) {
      return this.pickedColumnValues.length === this.columnValues.column_values.length;
    } else {
      return false;
    }
  }

  get selectDeselectTextDatasetFilter() {
    if (this.AreAllSelectedDatasetFilter) {
      return "Deselect All";
    } else {
      return "Select All";
    }
  }

  public getColumnIdFromColumnName(name: string) {

    const datasetKeys = Object.keys(this.dataset!.meta_data.columns);
    const systemKeys = Object.keys(this.systemColumns.columns);

    const keys = [...systemKeys, ...datasetKeys];

    for (const key of keys) {
      if ((datasetKeys.includes(key) && this.dataset!.meta_data.columns[key].name === name) || (systemKeys.includes(key) && this.systemColumns.columns[key].name === name)) {
        return parseInt(key, 10);
      }
    }
    return 0; // Return 0 if no matching key is found
  }


  public async onSelectionChangeDatasetFilter(value: any) {
    // Handle the selection change if needed
    if (!value) {
      console.log("No value provided");
      this.columnValues = {};
      return;
    }

    if (value.dtype === "timestamp") {
      this.filterTypeDataset = "timestamp";
    } else {
      this.filterTypeDataset = "must";
    }

    // collect the id of the column
    const columnId: number = this.getColumnIdFromColumnName(value.name);
    console.log('Column id:', columnId);

    this.pickedColumnValues = [];
    const allFilters = [...this.datasetFilters, ...this.modelFilters, ...this.tagFilters];
    // get data from backend
    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        columnId,
        allFilters,
      )
      .then((r) => {
        this.columnValues = r.data;
        console.log("filterColumnValues", this.columnValues);
      })
      .catch((error) => {
        this.columnValues = {};
        console.log("filterError", error.response);
      });

    if (this.$refs.autocomplete && 'cachedItems' in this.$refs.autocomplete) {
      this.$refs.autocomplete!.cachedItems = [];
    }
  }
  

  public async getSpeakerValues() {
    // Handle the selection change if needed
    const column = this.metadataOptions.find((column) => column.name === this.specialColumnMapper.speaker)
    
    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(column.key),
        [],
      )
      .then((r) => {
        this.speakerColumnValues = r.data;
        // Remove "conversation" entry
        console.log("speakerColumnValues", this.speakerColumnValues);
        this.speakerColumnValues.column_values = this.speakerColumnValues.column_values.filter(item => item.value !== "conversation");
      })
      .catch((error) => {
        this.speakerColumnValues = {};
        console.log("filterError", error.response);
      });
  }


  // Model filters

  public addModelFilter() {
    const filter: IModelFilterLabel = {
      filter_type: "label",
      label_id: this.pickedModelAndLabels.labels,
      inherited_from: this.pickedModelAndLabels.model,
      label_container: this.pickedModelAndLabels.label_container,
    };

    this.modelFilters.push(filter);

    this.pickedModelAndLabels = { model: -1, labels: [], label_container: -1 };
    this.search(true);
  }

  public pickModelAndLabel(labelId: number, modelId: number, labelContainerId: number) {
    this.pickedModelAndLabels.model = modelId;
    this.pickedModelAndLabels.labels = [labelId];
    this.pickedModelAndLabels.label_container = labelContainerId;
  }

  public deleteModelFilter(index: number) {
    this.modelFilters.splice(index, 1);
    this.search(true)
  }

  public expandRowModelFilter(index) {
    console.log("index", index);
    if (this.expandedRowModelFilter === index) {
      this.expandedRowModelFilter = null;
    } else {
      this.expandedRowModelFilter = index;
    }
    this.search(true);
  }


  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.connectedModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public getModelById(id: number) {
    return this.connectedModels.find((m) => m.id === id);
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get token() {
    return readToken(this.$store);
  }

  public async getTags() {
    await api
      .getDatasetTags(
        this.token,
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      )
      .then((r) => {
        this.tags = r.data;
      })
      .catch((error) => {
        console.log("error when getting tags", error);
      });
  }

  public created() {
    let speaker = this.$route.query.speaker|| "fqufhaskf";
    if (speaker !== "fqufhaskf" && typeof speaker === "string") {
        this.speedSpeakerToggle = speaker;
      }
    
  }

  public async mounted() {
    const routedSearchString = this.$route.query.search_string;

    if (routedSearchString && routedSearchString !== undefined) {
      this.searchQuery = routedSearchString.toString();
    }

    await dispatchGetDatasets(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
    await this.getSystemColumns();
    this.getDatasetPreview();
    this.getDatasetConnectedModels();
    this.createDatasetColumns();
    this.getSpeakerValues();
    await this.getSearchSettings();
    await this.getTags();
    this.unpackFilters();

    let mainClusterId = this.$route.query.main_cluster|| "fqufhaskf";
    let subClusterId = this.$route.query.sub_cluster || "fqufhaskf";
    // let speaker = this.$route.query.speaker|| "fqufhaskf";

    console.log("sub", subClusterId)
    
    
    if ((mainClusterId !== "fqufhaskf" && typeof mainClusterId === "string") || (subClusterId !== "fqufhaskf" && typeof subClusterId === "string") ){
      if (mainClusterId !== "fqufhaskf" && typeof mainClusterId === "string") {
      this.toggleSelectionDatasetColumns(this.metadataOptions.filter((column) => column.name === "main_cluster")[0])
      const columnId: number = this.getColumnIdFromColumnName("main_cluster");
      this.speedFilter("main_cluster", decodeURIComponent(mainClusterId.toString()), false)
      } 
    if (subClusterId !== "fqufhaskf" && typeof subClusterId === "string") {
      if(!this.isSelectedDatasetColumns({ name: "sub_cluster" })){
        this.toggleSelectionDatasetColumns(this.metadataOptions.filter((column) => column.name === "sub_cluster")[0])
      }else{
        console.log("Is already selected")
      }

      const columnId: number = this.getColumnIdFromColumnName("sub_cluster");
      this.speedFilter("sub_cluster", decodeURIComponent(subClusterId.toString()), false)
      } 
    }
    else {
      this.search(true);
    }
    // this.search(true);
    // console.log("Speaker", speaker)
    // if (speaker !== "fqufhaskf" && typeof speaker === "string") {
    //   console.log("IN HERE SER")
    //   this.speakerToggle(speaker);
    //   this.speedSpeakerToggle = speaker;
    // }else{
    //   this.speedSpeakerToggle = "all";
    // }
    // console.log(this.speedSpeakerToggle)
    

    
  }

  public async search(reset: boolean) {
    const allFilters = [...this.datasetFilters, ...this.modelFilters, ...this.tagFilters];
    let columnIds: string[] = [];

    this.selectedMetadata.forEach((obj) => {
      columnIds.push(obj.key)
    });

    this.loadingButton = true;
    if (reset) {
      this.page = 1;
    }

    this.searchError = null;

    // Set the searchQuery based on the mode
    if (this.searchMode === 'dual') {
      this.searchQuery = JSON.stringify({
        keyword: this.keywordQuery,
        ai: this.aiQuery
      });
    }

    await api
      .searchDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.searchQuery,
        columnIds,
        allFilters,
        this.page,
        this.similarity,
        this.searchMode
      )
      .then((r) => {
        this.queryResponse = r.data;
        this.cardinality = r.data.num_conversations;
        this.loadingButton = false;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
        this.loadingButton = false;
        this.searchError = error.response;
      });
  }

  @Watch('searchMode')
  public onSearchModeChange(newMode: string, oldMode: string) {
    if (newMode === 'dual') {
      // When switching to dual mode, split existing query if any
      this.keywordQuery = this.searchQuery;
      this.aiQuery = '';
    } else if (oldMode === 'dual') {
      // Only set searchQuery from keywordQuery when switching FROM dual mode
      this.searchQuery = this.keywordQuery;
    }
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  public isTagFilterApplied(tagId: number): boolean {
    return this.tagFilters.some((filter) => filter.tag_id === tagId);
  }

  public deleteTagFilter(index: number) {
    this.tagFilters.splice(index, 1);
    this.search(true);
  }

  public addTagFilter(tag: any) {
    console.log("Tag", tag)
    const filter: IModelFilterTag = {
      filter_type: 'tag',
      tag_id: tag.id,
    };
    
    this.tagFilters.push(filter);
    this.search(true);
  }

  public getTagById(id: number) {
    return this.tags.find((tag) => tag.id === id);
  }

  get canApplyFilter() {
    if (this.columnValues.dtype === 'timestamp') {
      return this.startDate !== '' || this.endDate !== '';
    }
    return this.pickedColumnValues.length > 0;
  }

  get canApplyModelFilter() {
    return (
      this.selectedFilterModel &&
      this.selectedFilterModel.status === 'deployed' &&
      this.pickedModelAndLabels.labels.length > 0
    );
  }

  public onModelSelectionChange(model: any) {
    if (model) {
      this.pickedModelAndLabels.model = model.id;
      this.pickedModelAndLabels.label_container = model.label_containers[0].id;
      this.pickedModelAndLabels.labels = [];
    } else {
      this.pickedModelAndLabels = { model: -1, labels: [], label_container: -1 };
    }
  }

  get sortedMetadataOptions() {
    return [...this.metadataOptions].sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  }

  @Watch('modelSortOrder')
  public onModelSortOrderChange(newOrder: string, oldOrder: string) {
    console.log(`Model sort order changed from ${oldOrder} to ${newOrder}`);
    // Add any additional actions you want to perform when the sort order changes
  }

  get canApplyTagFilter() {
    return this.pickedTag;
  }

  get sortedTags() {
    return [...this.tags].sort((a, b) => {
      const comparison = a.id - b.id; // Assuming 'id' is a proxy for date
      return this.tagSortOrder === 'asc' ? comparison : -comparison;
    });
  }
}
